import React, { useContext, useState, useReducer } from "react";
import { LunchForCauseDetailContext } from "../../container/CardContainer";
import RadioButton from "../RadioButton";
import Form from "../Form";
import Button from "../Button";
import Input from "../Input";
import * as Constant from "./constant";
import moment from "moment";
import Modal from "../Modal";
import modalReducer from "../../Reducer/modalReducer";
import GenericInput from "../GenericInput";
import FieldName from "../FieldName";
import { Mutation } from "react-apollo";
import { initiateRafflePurchaseProcess } from "../../Queries/detailQuery";
import { withRouter } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import "react-phone-number-input/style.css";
import PhoneInput, {isValidPhoneNumber} from "react-phone-number-input";
import stripeConfig from "../../Config/stripe-config";
import "./style.scss";
import { isMobileOnly } from "react-device-detect";
import HostImage from "../HostImage";
import Loader from "react-loader-spinner";

const RaffleDateAndTime = ({
  raffleEndDate,
  noRaffleLine,
  raffleWinnerAnnounced,
  raffleDrawDate,
  closedAt,
  cancelledAt,
  closedAtDate
}) => (
  <div className="raffle-date-time-container">
    {raffleWinnerAnnounced && (
      <div className="raffle-date-time-container-raffleClosesOn">
        {Constant.raffleWinnerAnnounced.toUpperCase()}
      </div>
    )}
    {closedAt && (
      <>
      <div className="raffle-date-time-container-raffleClosesOn">
        {Constant.RaffleClosedOn.toUpperCase()}
      </div>
      </>
    )}
    {!raffleWinnerAnnounced && !closedAt && !cancelledAt && (
      <div className="raffle-date-time-container-raffleClosesOn">
        {Constant.RaffleClosesOn.toUpperCase()}
      </div>
    )}
    {cancelledAt && (
      <div className="raffle-date-time-container-raffleClosesOn">
        {Constant.RaffleCancelledOn.toUpperCase()}
      </div>
    )}
    {!raffleDrawDate && !closedAt && !cancelledAt && (
      <div className="raffle-date-time-container-date-and-time">
        {`${moment(raffleEndDate).format("DD/MM/YYYY")} at ${moment(
          raffleEndDate
        ).format("hh:mm A")}`}{" "}
      </div>
    )}
    {raffleDrawDate && (
      <div className="raffle-date-time-container-date-and-time">
        {`${moment(raffleDrawDate).format("DD/MM/YYYY")} at ${moment(
          raffleDrawDate
        ).format("hh:mm A")}`}{" "}
      </div>
    )}
    {closedAt && (
      <div className="raffle-date-time-container-date-and-time">
        {`${moment(closedAtDate).format("DD/MM/YYYY")} at ${moment(
          closedAtDate
        ).format("hh:mm A")}`}{" "}
      </div>
    )}
    {cancelledAt && (
      <div className="raffle-date-time-container-date-and-time">
        {`${moment(cancelledAt).format("DD/MM/YYYY")} at ${moment(
          cancelledAt
        ).format("hh:mm A")}`}{" "}
      </div>
    )}
    {!noRaffleLine && (
      <div className="raffle-date-time-container-raffle-line">
        {Constant.RaffleLine}
      </div>
    )}
  </div>
);

const ContributorCount = ({ contributorsCount }) => (
  <div className="contributorCount-container">
    <div className="contributorCount-container-count">{contributorsCount}</div>
    <div className="contributorCount-container-line">
      {Constant.PeopleAlreadyContributed}
    </div>
  </div>
);

let SelectRaffleAmount = ({ possibleRaffleAmount, location }) => {
  possibleRaffleAmount.sort((item1, item2) => item1 - item2);
  const [isSelected, setSelected] = useState("");
  const [checked, setChecked] = useState(true);
  const [amount, setAmount] = useState("");
  const [error, setError] = useState(null);
  const [valueError, setValueError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errorModal, setErrorModal] = useState(false);
  const [target, setTarget] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const initialState = {
    open: false,
    validPhone: true
  };
  const [state, dispatch] = useReducer(modalReducer, initialState);

  const handleChange = e => {
    setSelected(e.target.value);
    setError(false);
    setValueError(false);
  };
  const handleChangeOther = e => {
    setSelected(e.target.value);
    setError("no-error");
    setValueError(false);
  };
  const handleSubmit = e => {
    e.preventDefault();
    dispatch({ type: "OPEN", payload: true });
  };
  const onChange = (e) => {
    setChecked(!e.target.checked);
  };
  const handleInput = e => {
    if (e.target.value >= possibleRaffleAmount[0] && e.target.value <= 99999) {
      setError(false);
      setValueError(false);
      setAmount(e.target.value);
    } else if (e.target.value > 99999) {
      setValueError(true);
      setError(true);
      setAmount(e.target.value);
    } else {
      setError(true);
      setAmount(e.target.value);
    }
  };

  const handlePhoneNumber = phoneNumber => {
    setPhoneNumber(phoneNumber);
    if (isValidPhoneNumber(phoneNumber)) {
      setErrorModal(false);
    } else {
      setErrorModal(true);
      setTarget("phone number");
    }
  };

  const handleChangeModal = e => {
    switch (e.target.name) {
      case "firstName":
        if (e.target.value.length === 0) {
          setFirstName(e.target.value);
        } else {
          setFirstName(e.target.value);
        }
        break;
      case "lastName":
        if (e.target.value.length === 0) {
          setLastName(e.target.value);
        } else {
          setLastName(e.target.value);
        }
        break;
      case "email":
        if (
          e.target.value.match(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ) {
          setErrorModal(false);
          setEmail(e.target.value);
        } else {
          setErrorModal(true);
          setEmail(e.target.value);
          setTarget("email");
        }
        break;
      case "phoneNumber":
        if (e.target.value.length === 10) {
          setPhoneNumber(e.target.value);
          setErrorModal(false);
        } else {
          setErrorModal(true);
          setPhoneNumber(e.target.value);
          setTarget("phone number");
        }
        break;
      default:
        break;
    }
  };
  const search = new URLSearchParams(location.search);
  const causeId = parseInt(search.get("causeid"));

  const handlePayment = async result => {
    setLoading(false);
    const {
      initiateRafflePurchaseProcess: { message, code, data }
    } = result;

    if (code === "200" && data) {
      setEmail("");
      setAmount("");
      setFirstName("");
      setPhoneNumber("");
      setLastName("");
      setResponseMessage("");
      setChecked(false);
      const stripe = await loadStripe(
        stripeConfig.public_key
      );
      const stripeObject = await stripe.redirectToCheckout({
        sessionId: data
      });
      console.log(stripeObject);
    } else {
      setResponseMessage(message);
    }
  };

  return (
    <>
      <Mutation
        mutation={initiateRafflePurchaseProcess}
        onCompleted={handlePayment}
      >
        {initialProcess => {
          const processPayment = e => {
            e.preventDefault();
            setLoading(true);
            let amount = parseFloat(
              e.target.children[8].innerText.match(/(\d+)/)
            );
            initialProcess({
              variables: {
                input: {
                  firstName: firstName,
                  lastName: lastName,
                  email: email,
                  phoneNumber: phoneNumber,
                  amount: amount,
                  lunchForCauseId: causeId,
                  isParticipatingInRaffle: checked
                }
              }
            });
          };

          return (
            <Modal open={state.open} responseMessage={responseMessage}>
              <div className="proceed-modal-body">
                <Form onSubmit={processPayment}>
                  <div className="modal-title">
                    {Constant.ProceedModalTitle}
                  </div>
                  <FieldName className="modal-field-name">
                    {Constant.ModalFieldName}
                  </FieldName>
                  <GenericInput
                    className="modal-input-small"
                    type="text"
                    name="firstName"
                    value={firstName}
                    onChange={handleChangeModal}
                  />{" "}
                  <GenericInput
                    className="modal-input-small"
                    type="text"
                    name="lastName"
                    value={lastName}
                    onChange={handleChangeModal}
                  />
                  <FieldName className="modal-field-name">
                    {Constant.ModalFieldEmail}
                  </FieldName>
                  <div>
                    <GenericInput
                      className="modal-input"
                      type="email"
                      name="email"
                      value={email}
                      onChange={handleChangeModal}
                    />
                  </div>
                  <FieldName className="modal-field-name">
                    {Constant.ModalFieldPhone}
                  </FieldName>
                  <div className="margin-24">
                    <PhoneInput
                      value={phoneNumber}
                      onChange={handlePhoneNumber}
                      error={
                        phoneNumber
                          ? isValidPhoneNumber(phoneNumber)
                            ? undefined
                            : "Invalid phone number"
                          : "Phone number required"
                      }
                      international
                      placeholder=""
                      className="Phone"
                    />
                    <div>
                      {errorModal && (
                        <div className="modal-error">
                          Please enter a valid {target}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="modal-bottom-buttons">
                    <Button
                      className="back-button"
                      onClick={() => {
                        dispatch({ type: "CLOSE", payload: false });
                        setEmail("");
                        setAmount("");
                        setFirstName("");
                        setPhoneNumber("");
                        setLastName("");
                        setResponseMessage("");
                        setErrorModal("");
                      }}
                    >
                      Go Back
                    </Button>
                    <Button
                      className={`modal-button disabled ${!errorModal &&
                        email !== "" &&
                        firstName !== "" &&
                        phoneNumber !== "" &&
                        lastName !== "" &&
                        "modal-enable"}`}
                    >
                      <div className="modal-button-text">
                      {`Proceed to Pay $${
                        isSelected === "Other" ? amount : isSelected
                      }`}{loading && <Loader type="TailSpin" color="#ffffff" height="15" width="15" />}
                      </div>
                    </Button>
                  </div>
                </Form>
              </div>
            </Modal>
          );
        }}
      </Mutation>
      <div className="select-raffle-amount-container">
        <div className="select-raffle-amount-container-text">
          {Constant.SelectRaffleAmount}
        </div>
        <div className="select-raffle-amount">
          <Form onSubmit={handleSubmit}>
            <div className="select-raffle-amount-all-options">
              {possibleRaffleAmount.map((amount, index) => (
                <div className="select-raffle-amount-option" key={index}>
                  <RadioButton
                    id={index}
                    amount={amount}
                    isSelected={isSelected === amount.toString()}
                    onChange={handleChange}
                  />
                </div>
              ))}
              <RadioButton
                id={"other"}
                amount={"Other"}
                isSelected={isSelected === "Other"}
                onChange={handleChangeOther}
              />
            </div>
            <div className="input-amount">
              {isSelected === "Other" && (
                <div className="input-amount-flex">
                  {amount !== "" && <div className="dollar-sign">$</div>}
                  <Input
                    amount="Amount"
                    value={amount}
                    handleInput={handleInput}
                    error={error}
                  />
                </div>
              )}
            </div>
            {isSelected === "Other" && (
              <div className="value-error-text">
                {valueError && <>{Constant.valueErrorText}</>}
              </div>
            )}
            {isSelected === "Other" && (
              <div className="minimum-amount">
                {Constant.minimumAmount + `$${possibleRaffleAmount[0]}`}
              </div>
            )}
            <WantToParticipateInRaffle checked={checked} onChange={onChange} />
            <Button
              type="submit"
              className={`proceed-button ${
                error || error === null ? "disabled" : "enabled"
              } `}
            >
              {Constant.Proceed}
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
};

const WantToParticipateInRaffle = ({ checked, onChange, className }) => (
  <div className="want-to-participate-container">
    <input type="checkbox" value={checked} onChange={onChange} className={`${className ? className : ''}`}/>
    <div className="want-to-participate-container-line">
      {Constant.WantToParticipate}
    </div>
  </div>
);

const LunchForCausePublishedDetails = () => {
  const {
    data:{getLunchForCauseDetails: { data }}
  } = useContext(LunchForCauseDetailContext);
  return (
    <div className="published-details">
      {isMobileOnly && <HostImage></HostImage>}
      <div className="title">{data.title}</div>
      <div className="benefit">{data.benefit}</div>
      <RaffleDateAndTime raffleEndDate={data.raffleEndDate} />
      <ContributorCount contributorsCount={data.contributorsCount} />
      {!isMobileOnly && (
        <SelectRaffleAmount possibleRaffleAmount={data.possibleRaffleAmount} />
      )}
    </div>
  );
};

SelectRaffleAmount = withRouter(SelectRaffleAmount);

export { LunchForCausePublishedDetails, ContributorCount, RaffleDateAndTime, WantToParticipateInRaffle };
