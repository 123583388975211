const bottomSheetReducer = (state, action) => {
  switch (action.type) {
    case "BOTTOM_SHEET_OPEN":
      return { ...state, bottom_sheet_open: action.payload };
    case "BOTTOM_SHEET_CLOSE":
      return {
        ...state,
        bottom_sheet_open: action.payload.open,
        bottom_sheet_isSelected: action.payload.selected,
        bottom_sheet_textbox_error: action.payload.error,
        bottom_sheet_checked: action.payload.checked,
        bottom_sheet_value_error: action.payload.valueError,
        bottom_sheet_amount: action.payload.amount,
      };
    case "BOTTOM_SHEET_ISSELECTED":
      return {
        ...state,
        bottom_sheet_isSelected: action.payload.selected,
        bottom_sheet_textbox_error: action.payload.error,
      };
    case "BOTTOM_SHEET_CHECKED":
      return { ...state, bottom_sheet_checked: action.payload };
    case "BOTTOM_SHEET_INPUT":
      return {
        ...state,
        bottom_sheet_amount: action.payload.amount,
        bottom_sheet_textbox_error: action.payload.error,
        bottom_sheet_value_error: action.payload.valueError,
      };
    case "BOTTOM_SHEET_OPEN_USER_MODAL":
      return {
        ...state,
        bottom_sheet_open_user_modal: action.payload,
      };
    case "BOTTOM_SHEET_CLOSE_USER_MODAL":
      return {
        ...state,
        bottom_sheet_open_user_modal: action.payload,
        mobile_modal_firstName: "",
        mobile_modal_lastName: "",
        mobile_modal_email: "",
        mobile_modal_phoneNumber: "",
        mobile_modal_error: false,
      };
    case "MOBILE_MODAL_FIRSTNAME":
      return { ...state, mobile_modal_firstName: action.payload };
    case "MOBILE_MODAL_LASTNAME":
      return { ...state, mobile_modal_lastName: action.payload };
    case "MOBILE_MODAL_EMAIL":
      return {
        ...state,
        mobile_modal_email: action.payload.email,
        mobile_modal_error: action.payload.error,
        mobile_modal_target: action.payload.target,
      };
    case "MOBILE_MODAL_PHONENUMBER":
      return {
        ...state,
        mobile_modal_phoneNumber: action.payload.phoneNumber,
        mobile_modal_target: action.payload.target,
        mobile_modal_error: action.payload.error,
      };
    case "CLEAR_DATA_AFTER_RESPONSE_STRIPE":
      return {
        ...state,
        mobile_modal_firstName: action.payload.firstName,
        mobile_modal_lastName: action.payload.lastName,
        mobile_modal_phoneNumber: action.payload.phoneNumber,
        mobile_modal_email: action.payload.email,
        bottom_sheet_isSelected: "",
        bottom_sheet_checked: "",
        mobile_modal_response_message: action.payload.responseMessage,
      };
    case "SET_PAYMENT_RESPONSE_MESSAGE":
      return {
        ...state,
        mobile_modal_response_message: action.payload,
      };
    case "START_LOADING":
      return {
        ...state,
        loading: action.payload
      }
    default:
      return state;
  }
};

export default bottomSheetReducer;
