const Proceed = "Proceed";
const SelectRaffleAmount = "Select Raffle Amount";
const PeopleAlreadyContributed = "People already contributed";
const RaffleLine =
  "Raffle will be drawn within 2 business days after the raffle close date";
const RaffleClosesOn = "Raffle Closes On";
const WantToParticipate = "I don't want to be part of the raffle but I would like to contribute to the cause";
const minimumAmount = "Minimum amount should be ";
const valueErrorText = "Please enter a valid amount";
const raffleWinnerAnnounced = "Raffle winner announced on";
const RaffleClosedOn = "Raffle closed on";
const RaffleCancelledOn = "Raffle Cancelled Date Was";
const ProceedModalTitle = "We would need basic details to proceed";
const ModalFieldName = "Name";
const ModalFieldEmail = "Email";
const ModalFieldPhone = "Phone";
const PhoneNoValidationMessage = "Please enter a valid phone number"
export {
  Proceed,
  SelectRaffleAmount,
  PeopleAlreadyContributed,
  RaffleLine,
  RaffleClosesOn,
  WantToParticipate,
  minimumAmount,
  valueErrorText,
  raffleWinnerAnnounced,
  RaffleClosedOn,
  RaffleCancelledOn,
  ProceedModalTitle,
  ModalFieldName,
  ModalFieldEmail,
  ModalFieldPhone,
  PhoneNoValidationMessage,
};