import React, { useEffect } from "react";
import AWSAppSyncClient from "aws-appsync";
import { Rehydrated } from "aws-appsync-react";
import { ApolloProvider } from "react-apollo";
import awsConfig from "./aws-exports";
import WindowLoading from "./components/WindowLoading";
import AppRoutes from "./AppRoutes";
import Amplify from "aws-amplify";
import { polyfill } from 'es6-promise';

Amplify.configure(awsConfig);

export const client = new AWSAppSyncClient({
  url: awsConfig.API.aws_appsync_graphqlEndpoint,
  region: awsConfig.API.aws_appsync_region,
  auth: {
    type: awsConfig.API.aws_appsync_authenticationType,
    apiKey: awsConfig.API.aws_appsync_apiKey
  },
  disableOffline: true
});

const rehydrated = ({ rehydrated }) => {
  return rehydrated ? <AppRoutes /> : <WindowLoading />
};

export function App() {

  useEffect(()=>{
    polyfill();
  }, []);

  return (
    <ApolloProvider client={client}>
      <Rehydrated render={rehydrated}></Rehydrated>
    </ApolloProvider>
  );
}
