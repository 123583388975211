const GlobalState = {
  bottom_sheet_open: false,
  bottom_sheet_isSelected: "",
  bottom_sheet_checked: true,
  bottom_sheet_amount: "",
  bottom_sheet_textbox_error: null,
  bottom_sheet_value_error: false,
  bottom_sheet_open_user_modal: false,

  mobile_modal_firstName: "",
  mobile_modal_lastName: "",
  mobile_modal_email: "",
  mobile_modal_phoneNumber: "",
  mobile_modal_error: false,
  mobile_modal_target: null,
  mobile_modal_response_message:"",

  loading: false
};

export default GlobalState;
